<template>
    <div class="page-content">
        <div class="grid">
            <div class="row no-gutters">
                <div class="col l-12 m-12 c-12">
                    <div class="page-list-body">
                        <div class="row no-gutters page-list-header">
                            <div class="col l-6 m-6 c-12">
                                <h4 class="page-list-title"><md-icon>list_alt</md-icon> <span>Đề nghị tạm ứng</span></h4>
                            </div>
                            <div class="col l-6 m-6 c-12">
                                <div class="page-list-action">
                                    <md-button @click="switchView(1)" :class="mode == 1 ? 'md-raised md-view btn-add  btn-active' : 'md-raised md-view btn-add'">ĐNTƯ</md-button>
                                    <md-button @click="switchView(2)" :class="mode == 2 ? 'md-raised md-view btn-add  btn-active' : 'md-raised md-view btn-add'">Chi tiết 1</md-button>
                                    <md-button @click="switchView(3)" :class="mode == 3 ? 'md-raised md-view btn-add  btn-active' : 'md-raised md-view btn-add'">Chi tiết 2</md-button>
                                    <md-button v-shortkey="['ctrl', 'p']" @shortkey="print()" @click="print()" class="md-raised md-view btn-add"><span>P</span>rint<md-tooltip>Print</md-tooltip></md-button>
                                    <md-button v-shortkey="['ctrl', 'm']" @shortkey="exportExcel()" @click="exportExcel()" class="md-raised md-primary btn-add">Export<md-tooltip>Xuất file Excel</md-tooltip></md-button>
                                </div>
                            </div>
                        </div>
                        <div class="form-body">
                            <div id="view-print" style="width: 850px; display: flex; flex-direction: column; justify-content: center;margin: 10px auto;">
                                <div style="height: 100px; display: flex; justify-content: space-between;">
                                    <div style="border-bottom: 1px solid #999; 
                                        width: 25%; display: flex;align-items: center; justify-content: center;
                                        border-top: 1px solid #999;border-left: 1px solid #999;">
                                        <img style="height: 50px; width: auto;" src="../../../assets/images/logo.png" />
                                    </div>
                                    <div style="border-bottom: 1px solid #999;
                                        border-left: 1px solid #999;
                                        border-top: 1px solid #999;
                                        border-right: 1px solid #999;
                                        width: 45%;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        text-transform: uppercase;
                                        font-weight: bold;">
                                        <div class="title"><h2>ĐỀ NGHỊ TẠM ỨNG</h2></div>
                                    </div>
                                    <div style="border-top: 1px solid #999; border-right: 1px solid #999;border-bottom: 1px solid #999; width: 30%; display: flex; flex-direction: column; justify-content: space-around; padding: 10px;">
                                        <div><span style="font-weight: bold;">Số: </span><span>{{entity.advancePaymentCode}}</span></div>
                                        <div><span style="font-weight: bold;">Ngày: </span><span>{{entity.advancePaymentDate}}</span></div>
                                    </div>
                                </div>
                                <div style="display: flex; flex-direction: column; padding: 10px 0;">
                                    <div style="display: flex; padding: 0;">
                                        <div style="font-size: 14px; padding-right: 10px;">Tên tôi là:</div>
                                        <div style="font-size: 14px;">{{ entity.staff.fullName  }}</div>
                                    </div>
                                    <div style="display: flex; padding: 0;">
                                        <div style="font-size: 14px; padding-right: 10px;">Phòng/ban:</div>
                                        <div style="font-size: 14px;">{{ entity.dept.name  }}</div>
                                    </div>
                                    <div style="display: flex; padding: 0;">
                                        <div style="font-size: 14px; padding-right: 10px;">Nội dung:</div>
                                        <div style="font-size: 14px;">{{ entity.note  }}</div>
                                    </div>
                                </div>
                                <div style="display: flex; flex-direction: column; padding: 0;">
                                    <div style="display: flex; padding: 0; ">
                                        <div style="font-size: 16px; padding-right: 10px; width: 47%;">
                                            <div style="font-weight: bold; font-size: 14px;">1.Khối kinh doanh: Doanh thu và chi hộ của KH: </div>
                                            <div style="font-size: 14px; font-style: italic;">Tên khách hàng:.........................</div>
                                            <div style="font-size: 14px; font-style: italic;">Số hợp đồng:............................</div>
                                            <div style="font-size: 14px; font-style: italic;">Lợi nhuận dự kiến.......................VNĐ</div>
                                        </div>
                                        <div style="font-size: 16px; padding-left: 0px; width: 53%;">
                                            <div style="font-weight: bold; font-size: 14px;">2.Khối Kỹ thuật - Khai thác:</div>
                                            <div style="font-size: 14px; font-style: italic; display: flex;">
                                                <span style="width: 45%;">
                                                    <label class="checkbox">
                                                        <input type="checkbox" /> 
                                                        <span class="helping-el"></span>
                                                    </label>
                                                    <span style="padding-left: 5px;">Phiếu giao NV số..........</span>
                                                </span>
                                                <span style="width: 55%;">
                                                    <label class="checkbox">
                                                        <input type="checkbox" /> 
                                                        <span class="helping-el"></span>
                                                    </label>
                                                    <span style="padding-left: 5px;">Sửa chữa thường xuyên</span>
                                                </span>
                                            </div>
                                            <div style="font-size: 14px; font-style: italic; display: flex;">
                                                <span style="width: 45%;">
                                                    <label class="checkbox">
                                                        <input type="checkbox" /> 
                                                        <span class="helping-el"></span>
                                                    </label>
                                                    <span style="padding-left: 5px;">Theo yêu cầu PKD</span>
                                                </span>
                                                <span style="width: 55%;">
                                                    <label class="checkbox">
                                                        <input type="checkbox" /> 
                                                        <span class="helping-el"></span>
                                                    </label>
                                                    <span style="padding-left: 5px;">Sửa chữa đột xuất, dự phòng</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="mode == 1" style="display: flex; margin: 0 auto;">
                                    <div style="display: flex; margin: 10px auto;">
                                        <table style="border-collapse: collapse;border-spacing: 0; min-height: auto !important;">
                                            <thead>
                                                <tr>
                                                    <th style="width:50px; padding: 5px 10px; border: 1px solid #e0e0e0;">Stt</th>
                                                    <th style="width:650px; padding: 5px 10px; border: 1px solid #e0e0e0;">Nội dung</th>
                                                    <th style="width:150px; padding: 5px 10px; border: 1px solid #e0e0e0;">Số tiền</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr  v-for="(item, index) in entity.estimateList" :key="'mode-1-' + index">
                                                    <td style="border: 1px solid #e0e0e0; padding: 5px 0px; text-align: center;">{{index + 1}}</td>
                                                    <td style="border: 1px solid #e0e0e0; padding: 5px 5px 5px 25px; text-align: left;">
                                                        <span v-if="item.estimate.note !== ''">{{ item.estimate.estimateCode + ": " + item.estimate.note }}</span>
                                                        <span v-else>{{ item.estimate.estimateCode }}</span>
                                                    </td>
                                                    <td style="border: 1px solid #e0e0e0; padding: 5px; text-align: right;">
                                                        {{ formatNumber(item.estimateAmount) }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tbody>
                                                <tr>
                                                    <td colspan="2" style="text-align: right; border: 1px solid #e0e0e0; padding: 5px 10px 5px 20px;"><span style="font-weight: bold;font-size: 16px;">Tổng tạm ứng</span></td>
                                                    <td style="border: 1px solid #e0e0e0; padding: 5px;text-align: right; "><span style="font-weight: bold; font-size: 16px;">{{ formatNumber(entity.pay) }}</span></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div v-if="mode == 2" style="display: flex; margin: 0 auto;">
                                    <div style="display: flex; margin: 10px auto;">
                                        <table style="border-collapse: collapse;border-spacing: 0; min-height: auto !important;">
                                            <thead>
                                                <tr>
                                                    <th style="width:50px; padding: 5px 10px; border: 1px solid #e0e0e0;">Stt</th>
                                                    <th style="width:300px; padding: 5px 10px; border: 1px solid #e0e0e0;">Tên phí</th>
                                                    <th style="width:200px; padding: 5px 10px; border: 1px solid #e0e0e0;">Loại phí</th>
                                                    <th style="width:150px; padding: 5px 10px; border: 1px solid #e0e0e0;">Dự toán</th>
                                                    <th style="width:150px; padding: 5px 10px; border: 1px solid #e0e0e0;">Tạm ứng</th>
                                                </tr>
                                            </thead>
                                            <tbody v-for="(item, index) in details" :key="'item-' + index">
                                                <tr>
                                                    <td style="border: 1px solid #e0e0e0; padding: 5px 0px; text-align: center;">{{romanize(index + 1)}}</td>
                                                    <td colspan="4" style="font-weight: bold; border: 1px solid #e0e0e0; padding: 5px; text-align: left;">{{ item.key }}</td>
                                                </tr>
                                                <tr v-for="(obj, i) in item.list" :key="'detail' + i">
                                                    <td style="border: 1px solid #e0e0e0; padding: 5px 0px; text-align: center;">{{i + 1}}</td>
                                                    <td style="border: 1px solid #e0e0e0; padding: 5px 5px 5px 25px; text-align: left;">{{ obj.fee.feeName }}</td>
                                                    <td style="border: 1px solid #e0e0e0; padding: 5px 10px 5px 10px;">{{ getFeeType(obj.type) }}</td>
                                                    <td style="border: 1px solid #e0e0e0; padding: 5px; text-align: right;">
                                                        {{ formatNumber(obj.amount) }}
                                                    </td>
                                                    <td style="border: 1px solid #e0e0e0; padding: 5px; text-align: right;">
                                                        {{ formatNumber(obj.advancePaymentAmount) }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colspan="3" style="font-weight: bold; border: 1px solid #e0e0e0; padding: 5px 5px; text-align: right;">Tổng cộng</td>
                                                    <td style="font-weight: bold; border: 1px solid #e0e0e0; padding: 5px; text-align: right;">{{ formatNumber(entity.estimateList.filter(x => x.estimate.estimateCode == item.key)[0].estimateAmount) }}</td>
                                                    <td style="font-weight: bold; border: 1px solid #e0e0e0; padding: 5px; text-align: right;">{{ formatNumber(entity.estimateList.filter(x => x.estimate.estimateCode == item.key)[0].pay) }}</td>
                                                </tr>
                                            </tbody>
                                            <tbody>
                                                <tr>
                                                    <td colspan="4" style="text-align: right; border: 1px solid #e0e0e0; padding: 5px 10px 5px 20px;"><span style="font-weight: bold;font-size: 16px;">Tổng tạm ứng</span></td>
                                                    <td style="border: 1px solid #e0e0e0; padding: 5px;text-align: right; "><span style="font-weight: bold; font-size: 16px;">{{ formatNumber(entity.pay) }}</span></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div v-if="mode == 1">
                                    <div style="display: flex; flex-direction: column; padding: 0;">
                                        <div style="display: flex; padding: 0; font-style: italic;">
                                            <div style="font-size: 14px; padding-right: 10px;">Bằng chữ :</div>
                                            <div style="font-size: 14px;">{{ entity.textAmount  }}</div>
                                        </div>
                                    </div>
                                    <div style="display: flex; flex-direction: column; padding: 0;">
                                        <div style="display: flex; padding: 0;">
                                            <div style="font-size: 14px; padding-right: 10px;">Thời gian hoàn ứng :</div>
                                            <div style="font-size: 14px; font-style: italic;">Từ ngày: {{ entity.advancePaymentDate  }}</div>
                                            <div style="font-size: 14px;padding-left: 10px; font-style: italic;">- Tới ngày: {{ entity.makeInvoiceDate  }}</div>
                                        </div>
                                    </div>
                                    <div style="display: flex; flex-direction: column; padding: 0;">
                                        <div style="display: flex; padding: 0;">
                                            <div style="font-size: 14px; padding-right: 10px;">Phương thức thanh toán</div>
                                        </div>
                                    </div>
                                    <div style="display: flex; flex-direction: column; padding: 0; font-style: italic;">
                                        <div style="display: flex; padding: 0;">
                                            <div style="font-size: 14px; padding-left: 30px;">
                                                <label class="checkbox">
                                                    <input type="checkbox" /> 
                                                    <span class="helping-el"></span>
                                                </label>
                                                <span style="padding-left: 5px;">Tiền mặt</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div style="display: flex; flex-direction: column; padding: 0; font-style: italic;">
                                        <div style="display: flex; padding: 0;">
                                            <div style="font-size: 14px; padding-left: 30px;">
                                                <label class="checkbox">
                                                    <input type="checkbox" /> 
                                                    <span class="helping-el"></span>
                                                </label>
                                                <span  style="padding-left: 5px;">Chuyển khoản</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div style="display: flex; flex-direction: column; padding: 10px 0 0 50px;">
                                        <div style="display: flex; padding: 0;">
                                            <div style="font-size: 14px; padding-right: 10px; font-weight: bold;">Tên tài khoản :</div>
                                            <div style="font-size: 14px;  font-style: italic;">{{ entity.staff.bankAccount  }}</div>
                                        </div>
                                    </div>
                                    <div style="display: flex; flex-direction: column; padding: 0 0 0 50px;">
                                        <div style="display: flex; padding: 0;">
                                            <div style="font-size: 14px; padding-right: 10px; font-weight: bold;">Số tài khoản :</div>
                                            <div style="font-size: 14px;  font-style: italic;">{{ entity.staff.bankNumber  }}</div>
                                        </div>
                                    </div>
                                    <div style="display: flex; flex-direction: column; padding: 0 0 0 50px;">
                                        <div style="display: flex; padding: 0;">
                                            <div style="font-size: 14px; padding-right: 10px; font-weight: bold;">Ngân hàng :</div>
                                            <div style="font-size: 14px;  font-style: italic;">{{ entity.staff.bankName  }}</div>
                                        </div>
                                    </div>
                                    <div style="display: flex; flex-direction: column; padding: 20px 0 0 0;">
                                        <div style="display: flex; padding: 0; flex-direction: column;">
                                            <div style="font-size: 14px; font-weight: bold;">Kính đề nghị Tổng Giám Đốc Công ty phê duyệt</div>
                                            <div style="font-size: 14px; font-weight: bold;">Xin trân trọng cảm ơn!</div>
                                        </div>
                                    </div>
                                </div>
                                <div style="display: flex;justify-content: space-between;padding: 20px 30px 50px 30px;">
                                    <div v-for="(item, index) in entity.approveList" :key="'approve-'+ index" style="display: flex; flex-direction: column; width: 40%; font-size: 17px; text-transform: none;">
                                        <div style="font-weight: bold; text-align: center;">
                                            {{ item.title }}
                                        </div>
                                        <div v-if="item.status == 2" style="color: #448aff; text-align: center;">
                                            Đã duyệt
                                        </div>
                                        <div v-else style="color: red; text-align: center;">
                                            Chưa duyệt
                                        </div>
                                        <div style="font-weight: bold; text-align: center; padding-top: 100px;">
                                            {{ item.staff.fullName }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import orderAdvancePaymentService from '@/api/orderAdvancePaymentService';
    import messageBox from '@/utils/messageBox';
    import { mapActions } from 'vuex';
    import { APP_CONFIG } from '@/utils/constants'

    export default ({
        metaInfo: {
            title: 'Chi tiết tạm ứng chi phí làm hàng'
        },
        data() {
            return {
                showDialog: false,
                id: 0,
                details: [],
                entity: { staff: { fullName: '' } },
                mode: 1
            }
        },
        created(){
            this.id = this.$route.params.id;
            this.getById();
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            exportExcel(){
                const link = document.createElement('a')
                link.href = APP_CONFIG.apiUrl + "/order-advance-payment/export-excel?id=" + this.id;
                link.target = '_blank';
                link.click()
                URL.revokeObjectURL(link.href)
            },

            switchView(mode){
                this.mode = mode;
                if(mode == 3){
                    this.$router.push('/order-advance-payment/view-2/' + this.id);
                }
            },

            print () {
                this.$htmlToPaper('view-print');
            },

            getById(){
                this.setLoading(true);
                orderAdvancePaymentService.getById(this.id).then((response) => {
                    if(response.statusCode == 200){
                        this.entity = response.data;
                        const details = this.groupBy(this.entity.details);
                        this.details = [];

                        Object.keys(details).forEach((item, index) => {
                            const objItem = { key: item, list: details[item] };
                            details[index] = details[item];
                            delete details[item];
                            this.details.push(objItem);
                        });
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            groupBy(list) {
                const groups = list.reduce((groups, item) => ({
                    ...groups,
                    [item.estimate.estimateCode]: [...(groups[item.estimate.estimateCode] || []), item]
                }), {});
                return groups;
            },

            romanize(num) {
                if (isNaN(num))
                    return NaN;
                var digits = String(+num).split(""),
                    key = ["","C","CC","CCC","CD","D","DC","DCC","DCCC","CM",
                        "","X","XX","XXX","XL","L","LX","LXX","LXXX","XC",
                        "","I","II","III","IV","V","VI","VII","VIII","IX"],
                    roman = "",
                    i = 3;
                while (i--)
                    roman = (key[+digits.pop() + (i * 10)] || "") + roman;
                return Array(+digits.join("") + 1).join("M") + roman;
            },
        },
    })
</script>

<style scoped>
    .btn-active {
        background-color: #08C2FF !important;
    }
</style>